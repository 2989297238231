import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from '@angular/router';
import { isEqual } from 'lodash-es';

export class PharRouteReuseStrategy implements BaseRouteReuseStrategy {
  shouldDetach(): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  store(): void {}

  shouldAttach(): boolean {
    return false;
  }

  retrieve(): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.data.noReuseParamsChange) {
      return future.routeConfig === curr.routeConfig && isEqual(future.params, curr.params);
    }

    return future.routeConfig === curr.routeConfig;
  }
}
