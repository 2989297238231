import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PharHttp } from '../core/http/http-client.service';
import { AppConfig } from '../core/config/app.config';
import { HttpHeaders } from '@angular/common/http';
import { UserModel, UsersCountGroupByStatus } from './user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private config: AppConfig,
    private http: PharHttp,
  ) {}

  getUsersCountGroupByStatus(): Observable<UsersCountGroupByStatus> {
    return this.http.get(this.config.buildUri('user') + '/AllUsersByOrganizationCount');
  }

  getUserBySub(sub: string): Observable<any> {
    return this.http.get(this.config.buildUri('user') + '/UserBySub/?sub=' + sub);
  }

  getNavigatorUsers(): Observable<any> {
    return this.http.get(this.config.buildUri('user') + '/UsersByOrganization');
  }

  getUsersByOrganization(): Observable<any> {
    return this.http.get(this.config.buildUri('user') + '/UsersByOrganization');
  }

  getUsersById(Id: number): Observable<any> {
    return this.http.get(this.config.buildUri('user') + '/UserById?userId=' + Id);
  }

  updateUser(user: UserModel): Observable<any> {
    return this.http.put(this.config.buildUri('user') + '/User', user);
  }

  getPendingUsersByOrganization(): Observable<any> {
    return this.http.get(this.config.buildUri('user') + '/PendingUsersByOrganization');
  }

  getInvitedUsersByOrganization(): Observable<any> {
    return this.http.get(this.config.buildUri('user') + '/InvatedUsersByOrganization');
  }

  assignUserToOrganization(userId: number, organizationId: number): Observable<any> {
    return this.http.post(this.config.buildUri('user') + '/AssignUserToOrganization', {
      userId,
      organizationId,
    });
  }

  uploadUserAvatar(formData: FormData): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.config.buildUri('user') + '/UserFileUpload', formData, options);
  }

  inviteUserRequest(invitationData: any): Observable<any> {
    return this.http.post(this.config.buildUri('user') + '/InviteUserRequest', invitationData);
  }

  handlePendingUsers(usersIds: number[], approve: boolean): Observable<any> {
    const pendingUsers = [];
    usersIds.forEach(id => {
      pendingUsers.push({
        userId: id,
        isApproved: approve,
      });
    });
    return this.http.post(this.config.buildUri('user') + '/HandlePendingUsers', { pendingUsers: pendingUsers });
  }

  resendUsersInvitations(usersIds: number[]): Observable<any> {
    return this.http.post(this.config.buildUri('user') + '/ResendInvitationEmails', usersIds);
  }

  deleteUserInvitation(userId: number): Observable<any> {
    return this.http.delete(this.config.buildUri('user') + `/DeleteInvitedUser/?id=${userId}`);
  }
}
