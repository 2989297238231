/* src/app/app.component.scss */
#main-container {
  padding: 0;
  background: #fff;
  min-height: 100%;
}
.main-content main {
  padding: 20px;
  margin-top: 94px;
  width: 100%;
  position: relative;
}
.main-content main .content-wrapper {
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 104px);
}
.main-content main .mat-flex {
  display: flex;
  justify-content: space-between;
}
.main-content main .pages-wrapper {
  height: 100%;
  overflow: auto;
}
/*# sourceMappingURL=app.component.css.map */
