import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from '../pages/auth-callback/auth-callback.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { LoginCallbackComponent } from '../pages/login-callback/login-callback.component';

/**
 * App routes
 */
const routes: Routes = [
  { path: '', redirectTo: '/dashboard/study/list', pathMatch: 'full' },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'login-callback',
    component: LoginCallbackComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'setup-organization',
  //   component: SetupOrganizationComponent
  // }
];

/**
 * This module is responsible only for application routes
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

/**
 * App routing class
 */
export class AppRoutingModule {}
