import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { errorPopup, globalLoading } from '../../store/actions/ui.actions';
import { AuditService } from '../audit.service';
import {
  getAuditActionTypes,
  getAuditActionTypesFailed,
  getAuditActionTypesSuccess,
  loadAuditTrailData,
  loadAuditTrailDataFailed,
  loadAuditTrailDataSuccess,
  selectAuditActionTypes,
  selectAuditTrailState,
} from './audit.actions';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';

@Injectable()
export class AuditEffects {
  private actions = inject(Actions);
  private auditService = inject(AuditService);
  private store = inject(Store<AppState>);

  loadAuditTrailData = createEffect(() => {
    return this.actions.pipe(
      ofType(loadAuditTrailData),
      withLatestFrom(this.store.select(selectAuditTrailState)),
      mergeMap(([, currentState]) => {
        return this.auditService.loadAuditTrailData(currentState.metaData, currentState.filter).pipe(
          mergeMap(({ data, metaData }) => {
            return [
              loadAuditTrailDataSuccess({
                metaData,
                auditItems: data,
              }),
              globalLoading(false),
            ];
          }),
          catchError(() => {
            return [loadAuditTrailDataFailed(), globalLoading(false)];
          }),
        );
      }),
    );
  });
  loadAuditActionTypes = createEffect(() => {
    return this.actions.pipe(
      ofType(getAuditActionTypes),
      withLatestFrom(this.store.select(selectAuditActionTypes)),
      filter(([, actionTypes]) => !actionTypes.length),
      mergeMap(([, actionTypes]) => {
        if (actionTypes.length) {
          return [
            getAuditActionTypesSuccess({
              actionTypes,
            }),
          ];
        } else {
          return this.auditService.loadAuditActionTypes().pipe(
            mergeMap(response => {
              return [
                getAuditActionTypesSuccess({
                  actionTypes: response,
                }),
              ];
            }),
            catchError(() => {
              return [
                getAuditActionTypesFailed(),
                errorPopup({ error: 'There is a problem with fetching audit action types' }),
              ];
            }),
          );
        }
      }),
    );
  });
}
