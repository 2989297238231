import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AssignService } from '../assign.service';
import { catchError, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as assignActions from './assign.actions';
import { resetCurrentAssign, updateAssignmentsInList, updateAssignOrder } from './assign.actions';
import { Store } from '@ngrx/store';
import { orderBy } from 'lodash-es';
import { AppState } from '../../store/models/app.state';
import { AssignModel } from '../assign.model';
import { addLoader, errorPopup, globalLoading, messagePopup, removeLoader } from '../../store/actions/ui.actions';
import { selectAssignListState } from './assign.state';

@Injectable()
export class AssignEffects {
  actions = inject(Actions);
  assignService = inject(AssignService);
  store = inject(Store<AppState>);

  getAssignsByProjectId = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.getAssignsByProjectId),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(action =>
        this.assignService.getAssignsByProject(action.projectId).pipe(
          mergeMap(res => {
            return [
              assignActions.updateAssignList({ assigns: res }),
              assignActions.loadAssignmentsCommentsState({ projectId: action.projectId, addLoader: action.addLoader }),
              removeLoader(action),
              globalLoading(false),
            ];
          }),
          catchError(() => {
            return [removeLoader(action), globalLoading(false)];
          }),
        ),
      ),
    ),
  );
  getAssignById = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.getAssignById),
      mergeMap(({ id }) =>
        this.assignService.getAssign(id).pipe(
          mergeMap(res => [assignActions.updateCurrentAssign({ assign: res })]),
          catchError(() => {
            return [];
          }),
        ),
      ),
    ),
  );
  softDeleteAssign = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.softDeleteAssign),
      withLatestFrom(this.store.select(selectAssignListState)),
      mergeMap(([deleteData, list]) => {
        const currentItem = {
          ...list.find(item => item.id === deleteData.id),
          isDeleted: true,
        };

        if (!currentItem.id) {
          return [errorPopup({ error: 'There is a problem with deleting this assignment' })];
        }

        let reorderedElements: AssignModel[];
        if (!!currentItem.groupName && !deleteData.deleteGroup) {
          // ungroup
          reorderedElements = list
            .filter(item => item.parentId === currentItem.id) // filter children
            .map(item => {
              return {
                ...item,
                parentId: null,
              };
            });
          const newRootList = orderBy(
            list.filter(item => !item.parentId && item.id !== deleteData.id),
            ['orderPriority'],
            'asc',
          );
          reorderedElements = [...newRootList, ...reorderedElements].map((item, index) => ({
            ...item,
            orderPriority: index,
          }));
        } else {
          reorderedElements = list
            .filter(item => item.orderPriority > currentItem.orderPriority && item.parentId === currentItem.parentId)
            .map(item => ({
              ...item,
              orderPriority: item.orderPriority - 1,
            }));
        }

        return this.assignService.updateAssignments([...reorderedElements, currentItem]).pipe(
          switchMap(res => {
            return [
              resetCurrentAssign(),
              updateAssignmentsInList({ assignments: res }),
              assignActions.removeAssignFromList({ id: deleteData.id }),
              removeLoader(assignActions.deleteAssign),
              messagePopup({ message: 'Successfully delete an Assignment' }),
            ];
          }),
          catchError(() => {
            return [
              removeLoader(assignActions.deleteAssign),
              errorPopup({ error: 'There is a problem with deleting this assignment' }),
            ];
          }),
        );
      }),
    ),
  );

  deleteAssignById = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.deleteAssign),
      withLatestFrom(this.store.select(state => state.assign.list)),
      mergeMap(([deleteData, list]) =>
        this.assignService.deleteAssign(deleteData.id, deleteData.deleteGroup).pipe(
          mergeMap(() => {
            const currentItem = list.find(item => item.id === deleteData.id);

            let reorderedElements: AssignModel[];
            if (!!currentItem.groupName && !deleteData.deleteGroup) {
              // ungroup
              reorderedElements = list
                .filter(item => item.parentId === currentItem.id) // filter children
                .map(item => {
                  return {
                    ...item,
                    parentId: null,
                  };
                });
              const newRootList = orderBy(
                list.filter(item => !item.parentId && item.id !== deleteData.id),
                ['orderPriority'],
                'asc',
              );
              reorderedElements = [...newRootList, ...reorderedElements].map((item, index) => ({
                ...item,
                orderPriority: index,
              }));
            } else {
              reorderedElements = list
                .filter(
                  item => item.orderPriority > currentItem.orderPriority && item.parentId === currentItem.parentId,
                )
                .map(item => ({
                  ...item,
                  orderPriority: item.orderPriority - 1,
                }));
            }

            return [
              resetCurrentAssign(),
              assignActions.removeAssignFromList({ id: deleteData.id }),
              updateAssignOrder({ items: reorderedElements }),
              messagePopup({ message: 'Successfully delete an Assignment' }),
            ];
          }),
          catchError(() => {
            return [errorPopup({ error: 'There is a problem with deleting this assignment' })];
          }),
        ),
      ),
    ),
  );

  updateAssignOrder = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.updateAssignOrder),
      withLatestFrom(this.store.select(state => state.project.current.project.id)),
      mergeMap(([assignmentData, projectId]) => {
        return this.assignService.updateAssignments(assignmentData.items).pipe(
          mergeMap(() => [assignActions.getAssignsByProjectId({ projectId })]),
          catchError(() => {
            return [errorPopup({ error: 'There is a problem with reordering this assignment' })];
          }),
        );
      }),
    ),
  );

  loadCommentsCounters = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.loadAssignmentsCommentsState),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(action => {
        return this.assignService.getCommentsCounter(action.projectId).pipe(
          mergeMap(states => [removeLoader(action), assignActions.populateCommentsState({ states })]),
          catchError(() => [removeLoader(action)]),
        );
      }),
    ),
  );

  updateAssignment = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.updateAssignment),
      mergeMap(({ assignment }) => {
        return this.assignService
          .updateAssignments([assignment])
          .pipe(
            mergeMap(res => [
              updateAssignmentsInList({ assignments: res }),
              messagePopup({ message: 'Successfully updated an Assignment' }),
            ]),
          );
      }),
    ),
  );
  updateAssignments = createEffect(() =>
    this.actions.pipe(
      ofType(assignActions.updateAssignments),
      mergeMap(({ assignments }) => {
        return this.assignService
          .updateAssignments(assignments)
          .pipe(
            mergeMap(res => [
              updateAssignmentsInList({ assignments: res }),
              messagePopup({ message: 'Successfully updated an Assignments' }),
            ]),
          );
      }),
    ),
  );
}
