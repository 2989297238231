import { appStateForm, FORM_INITIAL_STATE, FormState } from './form.state';
import * as formActions from './form.actions';
import { cloneDeep } from 'lodash-es';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { AppState } from '../../store/models/app.state';

const reducer = createReducer(
  appStateForm.form,
  on(formActions.updateFormList, (state, { formList }) => ({ ...state, list: formList })),
  on(formActions.removeForm, (state, { id }) => {
    const index = state.list.findIndex(form => form.id === id);
    return index > -1 ? { ...state, list: state.list.slice(0, index).concat(state.list.slice(index + 1)) } : state;
  }),
  on(formActions.resetCurrentForm, state => {
    return {
      ...state,
      current: {
        ...state.current,
        form: FORM_INITIAL_STATE(),
        formSnapshot: FORM_INITIAL_STATE(),
      },
    };
  }),
  on(formActions.populateCurrentForm, (state, { form }) => ({ ...state, current: { ...state.current, form: form } })),
  on(formActions.populateCurrentFormSnapshot, (state, { form }) => ({
    ...state,
    current: { ...state.current, formSnapshot: cloneDeep(form) },
  })),
  on(formActions.updateFormField, (state, { value, field }) => ({
    ...state,
    current: {
      ...state.current,
      form: { ...state.current.form, [field]: value },
    },
  })),
  on(formActions.pendingCreateUpdateForm, (state, { pending }) => ({
    ...state,
    current: { ...state.current, pending: pending },
  })),
  on(formActions.dispatchedCreateUpdateForm, (state, { dispatched }) => ({
    ...state,
    current: { ...state.current, dispatched: dispatched },
  })),
  on(formActions.successCreateUpdateForm, (state, { success }) => ({
    ...state,
    current: { ...state.current, success: success },
  })),
  on(formActions.formAddQuestion, (state, { question }) => {
    const questions = state.current.form.questions;
    const addQuestionIndex = questions.findIndex(d => (d.id ? d.id === question.id : d === question.id));
    if (addQuestionIndex === -1) {
      questions.push(question.id);
    }
    const questionsPopulated = state.current.form.questionsPopulated || {};
    questionsPopulated[question.id] = question;

    return {
      ...state,
      current: {
        ...state.current,
        form: {
          ...state.current.form,
          questions: questions,
          questionsPopulated: questionsPopulated,
        },
      },
    };
  }),
  on(formActions.formUpdateQuestion, (state, { question }) => {
    const questionsUpdatePopulated = state.current.form.questionsPopulated;
    if (questionsUpdatePopulated[question.id]) {
      questionsUpdatePopulated[question.id] = question;
    }
    return {
      ...state,
      current: {
        ...state.current,
        form: {
          ...state.current.form,
          questionsPopulated: questionsUpdatePopulated,
        },
      },
    };
  }),
  on(formActions.formRemoveQuestion, (state, { questionId }) => {
    const questionsRemovePopulated = state.current.form.questionsPopulated;
    if (questionsRemovePopulated[questionId]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete questionsRemovePopulated[questionId];
    }
    let questionRemove = state.current.form.questions;

    const questionRemoveIndex = questionRemove.findIndex(d => (d.id ? d.id === questionId : d === questionId));

    questionRemove = questionRemove.slice(0, questionRemoveIndex).concat(questionRemove.slice(questionRemoveIndex + 1));
    return {
      ...state,
      current: {
        ...state.current,
        form: {
          ...state.current.form,
          questions: questionRemove,
          questionsPopulated: questionsRemovePopulated,
          body: {
            ...state.current.form.body,
            pages: state.current.form.body.pages.map(page => {
              return {
                ...page,
                questions: page.questions.filter(q => q.id !== questionId),
              };
            }),
          },
        },
      },
    };
  }),
  on(formActions.updateFormInList, (state, { form }) => {
    const formIndex = state.list.findIndex(d => d.id === form.id);
    return formIndex > -1
      ? {
          ...state,
          list: [...[...state.list.slice(0, formIndex)], { ...form }, ...[...state.list.slice(formIndex + 1)]],
        }
      : state;
  }),
  on(formActions.checkCurrentFormForPendingChanges, (state, { changes }) => ({
    ...state,
    current: { ...state.current, pendingChanges: changes },
  })),
);

export function formReducer(state: FormState, action: Action): FormState {
  return reducer(state, action);
}

export const selectForm = (state: AppState) => state.form;
export const selectFormList = createSelector(selectForm, formState => formState.list);
