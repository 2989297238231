import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { PharStoreModule } from './store/phar-store.module';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';
import { PharProjectModule } from './project/project.module';
import { PharFormModule } from './form/form.module';
import { QuestionModule } from './question/question.module';
import { PermissionModule } from './permission/permission.module';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { PharBaseControlModelComponent } from './shared/phar-controls';
import { AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { environment } from '../environments/environment';
import { RouteReuseStrategy } from '@angular/router';
import { PharRouteReuseStrategy } from './app-routing/route-reuse-strategy';
import { PermissionService } from './permission/permission.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';

function permissionsFactory(
  appAuth: AuthService,
  permissionService: PermissionService,
  ngxPermissionsService: NgxPermissionsService,
): () => Promise<unknown> {
  return () =>
    firstValueFrom(
      appAuth.isAuthenticated$.pipe(
        switchMap(isAuthenticated => {
          if (isAuthenticated) {
            return permissionService.getUserPermissions().pipe(
              tap(permissions => {
                ngxPermissionsService.loadPermissions(permissions.map(p => p.permissionName));
              }),
            );
          } else {
            ngxPermissionsService.flushPermissions();
            return of(null);
          }
        }),
        map(() => null),
      ),
    );
}

@NgModule({
  declarations: [AppComponent, PharBaseControlModelComponent],
  imports: [
    AuthModule.forRoot({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [`${environment.protocol}://${environment.api.serverUrl}/api/*`],
      },
    }),
    BrowserAnimationsModule,
    SharedModule,
    BrowserModule,
    PharStoreModule,
    AppRoutingModule,
    CoreModule,
    PharFormModule,
    PharProjectModule,
    PagesModule,
    QuestionModule,
    PermissionModule,
    // DatasetModule,
    // UserModule,
    // MfmOrganizationModule,
    // RoleModule,
    // DatasetViewModule,
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(), // we need this due to be compatible with the auth0 interceptor
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: PharRouteReuseStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: permissionsFactory,
      deps: [AuthService, PermissionService, NgxPermissionsService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
