import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { PharStoreModule } from './store/phar-store.module';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';
import { PharProjectModule } from './project/project.module';
import { PharFormModule } from './form/form.module';
import { QuestionModule } from './question/question.module';
import { PermissionModule } from './permission/permission.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PharBaseControlModelComponent } from './shared/phar-controls';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { environment } from '../environments/environment';
import { RouteReuseStrategy } from '@angular/router';
import { PharRouteReuseStrategy } from './app-routing/route-reuse-strategy';


@NgModule({
  declarations: [AppComponent, PharBaseControlModelComponent],
  imports: [
    AuthModule.forRoot({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [`${environment.protocol}://${environment.api.serverUrl}/api/*`],
      },
    }),
    BrowserAnimationsModule,
    SharedModule,
    BrowserModule,
    PharStoreModule,
    AppRoutingModule,
    CoreModule,
    PharFormModule,
    PharProjectModule,
    PagesModule,
    QuestionModule,
    PermissionModule,
    // DatasetModule,
    // UserModule,
    // MfmOrganizationModule,
    // RoleModule,
    // DatasetViewModule,
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(), // we need this due to be compatible with the auth0 interceptor
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: PharRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
