import { appStateAssign, AssignState } from './assign.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as assignActions from './assign.actions';
import { cloneDeep } from 'lodash-es';
import { AssignModel } from '../assign.model';
import { Statuses } from '../../shared/models/statuses.enum';

const reducer = createReducer(
  appStateAssign.assign,
  on(assignActions.updateAssignList, (state, { assigns }) => ({ ...state, list: assigns })),
  on(assignActions.resetAssignList, state => ({ ...state, list: [] })),
  on(assignActions.updateCurrentAssign, (state, { assign }) => ({ ...state, current: assign })),
  on(assignActions.resetCurrentAssign, state => ({
    ...state,
    current: {
      createdAt: '',
      updatedAt: '',
      emails: [],
      formId: null,
      securityGroupId: null,
      organizationId: null,
      projectId: null,
      userIdCreated: null,
      userIds: [],
      userIdUpdated: null,
      formName: '',
      id: null,
      assignmentType: null,
      permissionsIds: [],
      accessUrl: '',
      securityGroupType: null,
      orderPriority: null,
      status: Statuses.PendingReview,
    },
  })),
  on(assignActions.updateAssignField, (state, { value, field }) => ({
    ...state,
    current: {
      ...state.current,
      [field]: value,
    },
  })),
  on(assignActions.removeAssignFromList, (state, { id }) => {
    const index = state.list.findIndex(assign => assign.id === id);
    return index > -1 ? { ...state, list: state.list.slice(0, index).concat(state.list.slice(index + 1)) } : state;
  }),
  on(assignActions.populateCommentsState, (state, { states }) => {
    const commentsState = {};
    // convert to object for faster access
    states.forEach(comment => {
      commentsState[comment.assignmentId] = {
        ...comment,
      };
    });
    return {
      ...state,
      commentsState,
    };
  }),
  on(assignActions.updateAssignmentInList, (state, { assignment }) => {
    return {
      ...state,
      list: state.list.map(d => (d.id === assignment.id ? { ...d, ...assignment } : d)),
    };
  }),
  on(assignActions.updateAssignmentsInList, (state, { assignments }) => {
    const list = cloneDeep(state.list);
    assignments.forEach((assign: AssignModel) => {
      const indexToFind = list.findIndex(d => d.id === assign.id);
      if (indexToFind > -1) {
        list[indexToFind] = { ...list[indexToFind], ...assign };
      }
    });

    return {
      ...state,
      list,
    };
  }),
);

export function assignReducer(state: AssignState, action: Action): AssignState {
  return reducer(state, action);
}
