import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';

import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppState } from './models/app.state';
import { UserEffects } from '../user/store/user.effects';
import { ProjectEffect } from '../project/store/project.effect';
import { FormEffects } from '../form/store/form.effects';
import { QuestionEffects } from '../question/store/question.effects';
import { AssignEffects } from '../assign/store/assign.effect';

/*import { PermissionEffects } from '../permission/store/permission.effect';*/
import { environment } from '../../environments/environment';
import { AuditEffects } from '../audit/store/audit.effects';
import { EventEffects } from '../events/store/event.effects';
import { DatasetEffect } from '../dataset/store/dataset.effect';
import { SettingsEffects } from './effects/settings.effects';
import { SiteEffects } from '../site/store/site.effects';
import { RolesAndPermissionsEffect } from '../roles-and-permissions/store/roles-and-permissions.effects';

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({ keys: ['ui', 'user', 'organization', 'permission'], rehydrate: true })(reducer);
}

const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    EffectsModule.forRoot([
      DatasetEffect,
      UserEffects,
      FormEffects,
      ProjectEffect,
      EventEffects,
      QuestionEffects,
      AssignEffects,

      /*PermissionEffects,*/
      SettingsEffects,
      SiteEffects,
      RolesAndPermissionsEffect,
      // GroupEffects,
      // RoleEffects,
      // RecordEffects,
      // OrganizationEffects,
      // DatasetViewEffects,
      // CustomersEffects,
      AuditEffects,
    ]),
  ],
  declarations: [],
  exports: [StoreModule],
})
export class PharStoreModule {}
