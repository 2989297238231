export const APP_ICONS = [
  'alert',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'bell',
  'bottomline',
  'calendar',
  'call',
  'card',
  'change',
  'check',
  'close',
  'consent',
  'critical',
  'download',
  'driver-card',
  'edit',
  'expired',
  'eye-off',
  'eye',
  'feather',
  'filter',
  'forms',
  'gender',
  'grid',
  'handle',
  'help',
  'home',
  'info',
  'insurance-card',
  'key',
  'language',
  'link-external',
  'list',
  'locate',
  'lock',
  'mail',
  'man',
  'middleline',
  'monitor',
  'more',
  'people',
  'phone',
  'placeholder',
  'plus',
  'printer',
  'question',
  'questionaires',
  'reconsents',
  'refresh',
  'required',
  'search',
  'settings',
  'sort-arrow-down',
  'sort-arrow-up',
  'sort-arrow',
  'swap',
  'topline',
  'trash',
  'upload',
  'user',
  'woman',
  'save',
  'send',
  'visibility',
  'library',
  'handyman',
  'folder',
  'duplicate',
  'play',
  'flag',
  'expand-5',
  'flag-1',
  'clock-rewind',
  'phone-2',
  'users',
  'link-new',
  'comment',
  'organisation',
  'monitor-01',
  'phone-01',
  'switch',
  'message-question-square',
];
