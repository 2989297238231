import { appStateDataset, DatasetState } from './dataset.state';
import { DatasetModel } from '../dataset.model';
import { cloneDeep } from 'lodash-es';
import { Action, createReducer, on } from '@ngrx/store';
import * as datasetAction from './dataset.actions';
import { IDatasetList } from '../../_old/_dataset/_dataset-lists/dataset-list.interface';
import { IDatasetListValues } from '../../_old/_dataset/_dataset-lists/dataset-list-values.interface';

const reducer = createReducer(
  appStateDataset.dataset,
  on(datasetAction.updateDatasetList, (state, { datasetList }) => ({
    ...state,
    list: datasetList,
  })),
  on(datasetAction.removeDataset, (state, { id }) => {
    const index = state.list.findIndex(dataset => dataset.id === id);
    return index > -1 ? { ...state, list: state.list.slice(0, index).concat(state.list.slice(index + 1)) } : state;
  }),
  on(datasetAction.updateDatasetInList, (state, { dataset }) => {
    const datasetIndex = state.list.findIndex(d => d.id === dataset.id);
    return datasetIndex > -1
      ? {
          ...state,
          list: [...[...state.list.slice(0, datasetIndex)], { ...dataset }, ...[...state.list.slice(datasetIndex + 1)]],
        }
      : state;
  }),
  on(datasetAction.updateDatasetField, (state, { field, value }) => ({
    ...state,
    current: { ...state.current, dataset: { ...state.current.dataset, [field]: value } },
  })),
  on(datasetAction.addDataset, (state, { dataset }) => ({
    ...state,
    list: [...state.list, { ...dataset }],
  })),
  on(datasetAction.resetCurrentDataset, state => ({
    ...state,
    current: {
      ...state.current,
      dataset: {
        id: null,
        organizationId: null,
        name: '',
        displayName: '',
        projects: [],
        forms: [],
        description: '',
        userIdCreated: null,
        userIdUpdated: null,
        type: 'dataset',
        shared: [],
        createdAt: '',
        updatedAt: '',
        data: {} as DatasetModel,
        settings: {},
        datasetSchema: {},
        datasetFieldTypes: [],
        dataSetVersion: null,
      },
      datasetSnapshot: {
        id: null,
        organizationId: null,
        name: '',
        displayName: '',
        projects: [],
        forms: [],
        description: '',
        userIdCreated: null,
        userIdUpdated: null,
        type: 'dataset',
        shared: [],
        createdAt: '',
        updatedAt: '',
        data: {} as DatasetModel,
        settings: {},
        datasetSchema: {},
        datasetFieldTypes: [],
        dataSetVersion: null,
      },
      pendingChanges: false,
      datasetLists: {
        list: [],
        current: {} as IDatasetList,
        currentListValues: [],
      },
    },
  })),
  on(datasetAction.pendingCreateDataset, (state, { pending }) => ({
    ...state,
    current: { ...state.current, pending },
  })),
  on(datasetAction.dispatchedCreateDataset, (state, { dispatched }) => ({
    ...state,
    current: { ...state.current, dispatched },
  })),
  on(datasetAction.successCreateDataset, (state, { success }) => ({
    ...state,
    current: { ...state.current, success },
  })),
  on(datasetAction.populateCurrentDataset, (state, { dataset }) => ({
    ...state,
    current: { ...state.current, dataset },
  })),
  on(datasetAction.updateDatasetTypes, (state, { types }) => ({
    ...state,
    types,
  })),
  on(datasetAction.checkCurrentDatasetForPendingChanges, (state, { hasChanges }) => ({
    ...state,
    current: {
      ...state.current,
      pendingChanges: hasChanges,
    },
  })),
  on(datasetAction.populateCurrentDatasetSnapshot, (state, { dataset }) => ({
    ...state,
    current: {
      ...state.current,
      datasetSnapshot: cloneDeep(dataset),
    },
  })),
  on(datasetAction.loadAllDatasetVersions, (state, { versions }) => ({
    ...state,
    versions,
  })),
  on(datasetAction.loadDatasetDataLists, (state, { lists }) => ({
    ...state,
    current: {
      ...state.current,
      datasetLists: {
        ...state.current.datasetLists,
        list: lists,
      },
    },
  })),
  on(datasetAction.updateDatasetDataListField, (state, { field, value }) => ({
    ...state,
    current: {
      ...state.current,
      datasetLists: {
        ...state.current.datasetLists,
        current: {
          ...state.current.datasetLists.current,
          [field]: value,
        },
      },
    },
  })),
  on(datasetAction.loadCurrentDatasetDataList, (state, { list }) => ({
    ...state,
    current: {
      ...state.current,
      datasetLists: {
        ...state.current.datasetLists,
        current: list,
      },
    },
  })),
  on(datasetAction.resetCurrentDatasetDataList, state => ({
    ...state,
    current: {
      ...state.current,
      datasetLists: {
        ...state.current.datasetLists,
        current: {} as IDatasetList,
      },
    },
  })),
  on(datasetAction.addDatasetDataListValue, (state, { label, value }) => ({
    ...state,
    current: {
      ...state.current,
      datasetLists: {
        ...state.current.datasetLists,
        current: {
          ...state.current.datasetLists.current,
          fields: [...state.current.datasetLists.current.fields, { label, value }],
        },
      },
    },
  })),
  on(datasetAction.updateDatasetDataListValue, (state, { oldValue, oldLabel, newValue, newLabel }) => {
    const modifiedFields = cloneDeep(state.current.datasetLists.current.fields);
    modifiedFields.forEach((item: IDatasetListValues) => {
      if (item.value === oldValue && item.label === oldLabel) {
        item.value = newValue;
        item.label = newLabel;
      }
    });
    return {
      ...state,
      current: {
        ...state.current,
        datasetLists: {
          ...state.current.datasetLists,
          current: {
            ...state.current.datasetLists.current,
            fields: modifiedFields,
          },
        },
      },
    };
  }),
  on(datasetAction.deleteDatasetDataListValue, (state, { value, label }) => {
    const modifiedFields: IDatasetListValues[] = cloneDeep(state.current.datasetLists.current.fields);
    const forDeleteIndex = modifiedFields.findIndex(
      (item: IDatasetListValues) => item.value === value && item.label === label,
    );
    if (forDeleteIndex > -1) {
      modifiedFields.splice(forDeleteIndex, 1);
    }
    return {
      ...state,
      current: {
        ...state.current,
        datasetLists: {
          ...state.current.datasetLists,
          current: {
            ...state.current.datasetLists.current,
            fields: modifiedFields,
          },
        },
      },
    };
  }),
  on(datasetAction.loadCurrentDatasetDataListById, (state, { id }) => {
    const list: IDatasetList[] = state.current.datasetLists.list;
    const target = list.find((item: IDatasetList) => item.id === id);
    return {
      ...state,
      current: {
        ...state.current,
        datasetLists: {
          ...state.current.datasetLists,
          current: target ? target : ({} as IDatasetList),
        },
      },
    };
  }),
);

export function datasetReducer(state: DatasetState, action: Action): DatasetState {
  return reducer(state, action);
}
