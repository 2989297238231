import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getRoleById,
  populateCurrentRole,
  populateCurrentRoleSnapshot,
  createRole,
  pendingCreateUpdateRole,
  successCreateUpdateRole,
  updateRole,
  loadRolesList,
  populateRolesList,
  deleteRole,
  updateRoleField,
  removeRoleFromList,
  updateRoleFromList,
  checkCurrentRoleForPendingChanges,
  populateApplications,
  getApplications,
  setActiveApplicationById,
  loadPermissionsList,
  populatePermissionsList,
  loadRolePermissionsList,
  populateRolePermissionsList,
  loadOnlyRolesList,
} from './roles-and-permissions.actions';

import { AppState, getState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { addLoader, errorPopup, globalLoading, messagePopup, removeLoader } from '../../store/actions/ui.actions';
import { isEqual } from 'lodash-es';
import { RolesAndPermissionsService } from '../roles-and-permissions.service';
import { IRole } from '../../shared/models/role.interface';
import { IApplication } from '../../shared/models/aplications.interface';

@Injectable()
export class RolesAndPermissionsEffect {
  actions = inject(Actions);
  rolesService = inject(RolesAndPermissionsService);
  store = inject(Store<AppState>);

  getRoleById = createEffect(() => {
    return this.actions.pipe(
      ofType(getRoleById),
      mergeMap(({ id }) =>
        this.rolesService.getRoleById(id).pipe(
          mergeMap((res: IRole) => [
            populateCurrentRole({ role: res }),
            populateCurrentRoleSnapshot({ role: res }),
            globalLoading(false),
          ]),
          catchError(() => {
            return [globalLoading(false)];
          }),
        ),
      ),
    );
  });

  getApplications = createEffect(() => {
    return this.actions.pipe(
      ofType(getApplications),
      mergeMap(({ defaultById }) => {
        return this.rolesService.getApplications().pipe(
          mergeMap((applications: IApplication[]) => {
            return [
              populateApplications({ applications }),
              setActiveApplicationById({ id: defaultById }),
              globalLoading(false),
            ];
          }),
          catchError(() => {
            return [globalLoading(false)];
          }),
        );
      }),
    );
  });

  createRole = createEffect(() => {
    return this.actions.pipe(
      ofType(createRole),
      mergeMap(({ role }) =>
        this.rolesService.create(role).pipe(
          mergeMap(() => [
            pendingCreateUpdateRole({ pending: false }),
            successCreateUpdateRole({ success: true }),
            messagePopup({ message: 'Role created' }),
            globalLoading(false),
          ]),
          catchError(() => {
            return [
              globalLoading(false),
              messagePopup({ message: 'There is an issue with creating role' }),
              pendingCreateUpdateRole({ pending: false }),
              successCreateUpdateRole({ success: false }),
            ];
          }),
        ),
      ),
    );
  });

  updateRole = createEffect(() => {
    return this.actions.pipe(
      ofType<ReturnType<typeof updateRole>>(updateRole),
      mergeMap(({ role }) =>
        this.rolesService.update(role).pipe(
          mergeMap(res => [
            // (populateCurrentRole({ role: res })),
            // (populateCurrentRoleSnapshot({ role: res })),
            pendingCreateUpdateRole({ pending: false }),
            successCreateUpdateRole({ success: true }),
            messagePopup({ message: 'Role updated' }),
            updateRoleFromList({ role: res }),
            globalLoading(false),
          ]),
          catchError(() => [
            globalLoading(false),
            messagePopup({ message: 'There is an issue with updating role' }),
            pendingCreateUpdateRole({ pending: false }),
            successCreateUpdateRole({ success: false }),
          ]),
        ),
      ),
    );
  });

  loadRolesList = createEffect(() => {
    return this.actions.pipe(
      ofType(loadRolesList),
      mergeMap(({ serverIdentifier }) =>
        this.rolesService.getRolesList(serverIdentifier).pipe(
          mergeMap(roles => [populateRolesList({ roles }), globalLoading(false)]),
          catchError(() => [globalLoading(false)]),
        ),
      ),
    );
  });

  loadOnlyRolesList = createEffect(() => {
    return this.actions.pipe(
      ofType(loadOnlyRolesList),
      mergeMap(() =>
        this.rolesService.getOnlyRolesList().pipe(
          mergeMap(roles => [populateRolesList({ roles }), globalLoading(false)]),
          catchError(() => [globalLoading(false)]),
        ),
      ),
    );
  });

  deleteRole = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteRole),
      mergeMap(({ id }) =>
        this.rolesService.deleteRole(id).pipe(
          mergeMap(() => [removeRoleFromList({ id }), messagePopup({ message: 'Successfully delete Role' })]),
          catchError(() => [errorPopup({ error: 'There is a problem with deleting this Role' })]),
        ),
      ),
    );
  });

  listenForChanges = createEffect(() => {
    return this.actions.pipe(
      ofType(updateRoleField, successCreateUpdateRole, populateCurrentRoleSnapshot),
      mergeMap(() => {
        const current = getState(this.store).roles.current.role;
        const snapshot = getState(this.store).roles.current.roleSnapshot;
        return [
          checkCurrentRoleForPendingChanges({
            hasChanges: !isEqual(current, snapshot),
          }),
        ];
      }),
      catchError(() => []),
    );
  });

  loadPermissionsList = createEffect(() => {
    return this.actions.pipe(
      ofType(loadPermissionsList),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(action =>
        this.rolesService.getPermissionsList(action.serverIdentifier).pipe(
          mergeMap(permissions => [
            populatePermissionsList({ permissions }),
            removeLoader(action),
            globalLoading(false),
          ]),
          catchError(() => [removeLoader(action), globalLoading(false)]),
        ),
      ),
    );
  });

  loadRolePermissionsList = createEffect(() => {
    return this.actions.pipe(
      ofType(loadRolePermissionsList),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(action =>
        this.rolesService.getRolePermissionsList(action.serverIdentifier, action.studyName).pipe(
          mergeMap(roles => [populateRolePermissionsList({ roles }), removeLoader(action), globalLoading(false)]),
          catchError(() => [removeLoader(action), globalLoading(false)]),
        ),
      ),
    );
  });
}
