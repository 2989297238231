import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

import { LoginCallbackComponent } from './login-callback/login-callback.component';

const declarations = [
  AuthCallbackComponent,
  // PrivacyPolicyComponent,
  // CookiePolicyComponent,
  // TermsOfUseComponent,
  LoginCallbackComponent,
];

@NgModule({
  imports: [
    CommonModule,
    // DatasetModule,
    SharedModule,
    PagesRoutingModule,
  ],
  declarations,
  exports: [...declarations],
})
export class PagesModule {}
