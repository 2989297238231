import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  deleteUserInvitation,
  fetchUsersCountGroupByStatus,
  getInvitedUsersByOrganization,
  getNavigatorUsers,
  getPendingUsersByOrganization,
  getUserById,
  getUserBySub,
  getUsersByOrganization,
  handlePendingUsers,
  inviteUserRequest,
  loadUsersCountGroupByStatus,
  resendUsersInvitations,
  updateCurrentUser,
  updateCurrentUserField,
  updateUser,
  updateUserList,
  updateUserProfile,
  uploadUserAvatar,
} from './user.actions';
import { UserService } from '../user.service';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState, getState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { errorPopup, globalLoading, messagePopup } from '../../store/actions/ui.actions';

/*import { getUserPermissions } from '../../permission/store/permission.actions';*/
import { UserModel } from '../user.model';

@Injectable()
export class UserEffects {
  actions = inject(Actions);
  userService = inject(UserService);
  store = inject(Store<AppState>);
  getUsersCountGroupByStatus = createEffect(() => {
    return this.actions.pipe(
      ofType(fetchUsersCountGroupByStatus),
      switchMap(() => this.userService.getUsersCountGroupByStatus()),
      mergeMap(usersCount => [loadUsersCountGroupByStatus({ usersCount })]),
    );
  });
  getUserBySub = createEffect(() => {
    return this.actions.pipe(
      ofType(getUserBySub),
      mergeMap(({ user }) =>
        this.userService.getUserBySub(user.sub).pipe(
          mergeMap((res: UserModel) => {
            return [updateUserProfile({ user: { ...res, ...user } })];
          }),
          catchError(() => {
            return [];
          }),
        ),
      ),
    );
  });

  /*assignUserToOrganization = createEffect(() => {
    return this.actions.pipe(
      ofType(assignUserToOrganization),
      mergeMap(({ organizationId, userId }) =>
        this.userService.assignUserToOrganization(userId, organizationId).pipe(
          mergeMap(res => {
            const profile = getState(this.store).user.profile;
            return [
              (globalLoading(false)),
              (getUserPermissions()),
              (updateUserProfile({ user: { ...profile, ...res } }))
            ];
          }),
          catchError(err => {
            return [
              (globalLoading(false))
            ];
          }))));
  });*/
  getNavigatorUsers = createEffect(() => {
    return this.actions.pipe(
      ofType(getNavigatorUsers),
      mergeMap(() =>
        this.userService.getNavigatorUsers().pipe(
          mergeMap(res => {
            return [updateUserList({ users: res })];
          }),
          catchError(() => {
            return [];
          }),
        ),
      ),
    );
  });
  getUserById = createEffect(() => {
    return this.actions.pipe(
      ofType(getUserById),
      mergeMap(({ id }) =>
        this.userService.getUsersById(id).pipe(
          mergeMap(res => {
            return [updateCurrentUser({ user: res }), globalLoading(false)];
          }),
          catchError(() => {
            return [globalLoading(false)];
          }),
        ),
      ),
    );
  });
  getUsersByOrganization = createEffect(() => {
    return this.actions.pipe(
      ofType(getUsersByOrganization),
      mergeMap(() =>
        this.userService.getUsersByOrganization().pipe(
          mergeMap(res => {
            return [updateUserList({ users: res }), globalLoading(false)];
          }),
          catchError(() => {
            return [globalLoading(false)];
          }),
        ),
      ),
    );
  });
  getPendingUsersByOrganization = createEffect(() => {
    return this.actions.pipe(
      ofType(getPendingUsersByOrganization),
      mergeMap(() =>
        this.userService.getPendingUsersByOrganization().pipe(
          mergeMap(res => {
            return [updateUserList({ users: res }), globalLoading(false)];
          }),
          catchError(() => {
            return [globalLoading(false)];
          }),
        ),
      ),
    );
  });
  getInvitedUsersByOrganization = createEffect(() => {
    return this.actions.pipe(
      ofType(getInvitedUsersByOrganization),
      mergeMap(() =>
        this.userService.getInvitedUsersByOrganization().pipe(
          mergeMap(res => {
            return [updateUserList({ users: res }), globalLoading(false)];
          }),
          catchError(() => {
            return [globalLoading(false)];
          }),
        ),
      ),
    );
  });
  handlePendingUsers = createEffect(() => {
    return this.actions.pipe(
      ofType(handlePendingUsers),
      mergeMap(({ approve, usersIds }) =>
        this.userService.handlePendingUsers(usersIds, approve).pipe(
          mergeMap(() => {
            return [getPendingUsersByOrganization(), fetchUsersCountGroupByStatus()];
          }),
          catchError(() => {
            return [];
          }),
        ),
      ),
    );
  });
  resendUsersInvitations = createEffect(() => {
    return this.actions.pipe(
      ofType(resendUsersInvitations),
      mergeMap(({ usersIds }) =>
        this.userService.resendUsersInvitations(usersIds).pipe(
          mergeMap(() => {
            return [getInvitedUsersByOrganization(), globalLoading(false)];
          }),
          catchError(() => {
            return [globalLoading(false)];
          }),
        ),
      ),
    );
  });
  deleteUsersInvitations = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteUserInvitation),
      mergeMap(({ userId }) =>
        this.userService.deleteUserInvitation(userId).pipe(
          mergeMap(() => {
            return [fetchUsersCountGroupByStatus(), getInvitedUsersByOrganization(), globalLoading(false)];
          }),
          catchError(() => {
            return [globalLoading(false)];
          }),
        ),
      ),
    );
  });
  updateUser = createEffect(() => {
    return this.actions.pipe(
      ofType(updateUser),
      mergeMap(({ isCurrentProfile, user }) =>
        this.userService.updateUser(user).pipe(
          mergeMap(res => {
            if (isCurrentProfile) {
              const profile = getState(this.store).user.profile;
              return [
                globalLoading(false),
                updateCurrentUser({ user: res }),
                updateUserProfile({ user: { ...profile, ...res } }),
                messagePopup({ message: 'User is updated.' }),
              ];
            } else {
              return [
                globalLoading(false),
                updateCurrentUser({ user: res }),
                messagePopup({ message: 'User is updated.' }),
              ];
            }
          }),
          catchError(() => {
            return [globalLoading(false), errorPopup({ error: 'There is a problem with updating profile details.' })];
          }),
        ),
      ),
    );
  });
  uploadUserAvatar = createEffect(() => {
    return this.actions.pipe(
      ofType(uploadUserAvatar),
      mergeMap(({ formData }) =>
        this.userService.uploadUserAvatar(formData).pipe(
          mergeMap((res: string) => {
            return [updateCurrentUserField({ field: 'avatarURL', value: res })];
          }),
          catchError(() => {
            return [];
          }),
        ),
      ),
    );
  });
  inviteUserRequest = createEffect(() => {
    return this.actions.pipe(
      ofType(inviteUserRequest),
      mergeMap(({ invitationData }) =>
        this.userService.inviteUserRequest(invitationData).pipe(
          mergeMap(() => {
            return [
              fetchUsersCountGroupByStatus(),
              globalLoading(false),
              messagePopup({ message: 'User will receive an invitation e-mail' }),
            ];
          }),
          catchError(() => {
            return [globalLoading(false)];
          }),
        ),
      ),
    );
  });
}
