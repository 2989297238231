import { Action, createReducer, on } from '@ngrx/store';
import { appStateEvent, CURRENT_PROJECT_EVENT_INITIAL_STATE, EventState } from './event.state';
import * as eventActions from './event.actions';
import { cloneDeep } from 'lodash-es';
import { ProjectEventModel } from '../project-event.model';
import { ICommentsState } from '../../shared/models/comments-state.interface';

const reducer = createReducer(
  appStateEvent.event,
  on(eventActions.dispatchedCreateUpdateEvent, (state, { dispatched }) => ({
    ...state,
    current: { ...state.current, dispatched: dispatched },
  })),
  on(eventActions.pendingCreateUpdateEvent, (state, { pending }) => ({
    ...state,
    current: { ...state.current, pending: pending },
  })),
  on(eventActions.successCreateUpdateEvent, (state, { success }) => ({
    ...state,
    current: { ...state.current, success: success },
  })),
  on(eventActions.updateEventField, (state, { value, field }) => ({
    ...state,
    current: {
      ...state.current,
      projectEvent: {
        ...state.current.projectEvent,
        event: {
          ...state.current.projectEvent.event,
          [field]: value,
        },
      },
    },
  })),
  on(eventActions.updateEventFields, (state, { event }) => ({
    ...state,
    current: {
      ...state.current,
      projectEvent: {
        ...state.current.projectEvent,
        event: {
          ...state.current.projectEvent.event,
          ...event,
        },
      },
    },
  })),
  on(eventActions.checkCurrentEventForPendingChanges, (state, { changes }) => ({
    ...state,
    current: { ...state.current, pendingChanges: changes },
  })),

  // project events
  on(eventActions.removeProjectEvent, (state, { projectEventId }) => {
    const index = state.listProjectEvents.findIndex(projectEvent => projectEvent.id === projectEventId);
    return index > -1
      ? {
          ...state,
          listProjectEvents: state.listProjectEvents.slice(0, index).concat(state.listProjectEvents.slice(index + 1)),
        }
      : state;
  }),
  on(eventActions.resetProjectEventList, state => ({ ...state, listProjectEvents: [] })),
  on(eventActions.changeCurrentProjectEvent, (state, { eventId }) => {
    const projectEvent = state.listProjectEvents.find(projectEvent => projectEvent.eventId === eventId);
    return projectEvent.eventId > -1
      ? {
          ...state,
          current: {
            ...state.current,
            projectEvent: cloneDeep(projectEvent),
            projectEventSnapshot: cloneDeep(projectEvent),
          },
        }
      : state;
  }),
  on(eventActions.revertEventEditorChanges, state => ({
    ...state,
    current: { ...state.current, projectEvent: cloneDeep(state.current.projectEventSnapshot) },
  })),
  on(eventActions.resetCurrentProjectEvent, state => {
    return {
      ...state,
      current: {
        ...state.current,
        projectEvent: {
          ...CURRENT_PROJECT_EVENT_INITIAL_STATE,
        },
        projectEventSnapshot: {
          ...CURRENT_PROJECT_EVENT_INITIAL_STATE,
        },
      },
    };
  }),
  on(eventActions.populateCurrentProjectEvent, (state, { projectEvent }) => ({
    ...state,
    current: {
      ...state.current,
      projectEvent: projectEvent,
    },
  })),
  on(eventActions.populateCurrentProjectEventSnapshot, (state, { projectEvent }) => ({
    ...state,
    current: { ...state.current, projectEventSnapshot: cloneDeep(projectEvent) },
  })),
  on(eventActions.updateProjectEventList, (state, { projectEventList }) => ({
    ...state,
    listProjectEvents: projectEventList,
  })),
  on(eventActions.updateProjectEventInList, (state, { projectEvent }) => {
    const projectEventIndex = state.listProjectEvents.findIndex(d => d.id === projectEvent.id);
    return projectEventIndex > -1
      ? {
          ...state,
          listProjectEvents: [
            ...[...state.listProjectEvents.slice(0, projectEventIndex)],
            { ...projectEvent },
            ...[...state.listProjectEvents.slice(projectEventIndex + 1)],
          ],
        }
      : state;
  }),
  on(eventActions.updateEventNotificationsList, (state, { eventNotifications }) => ({
    ...state,
    listEventNotifications: eventNotifications,
  })),
  on(eventActions.resetEventNotificationsList, state => ({
    ...state,
    listEventNotifications: [],
  })),
  on(eventActions.populateProjectEventsCommentsState, (state, { states }) => {
    const commentsState = {};
    states.forEach((item: ICommentsState) => {
      commentsState[item.projectEventId] = { ...item };
    });
    return {
      ...state,
      commentsState,
    };
  }),
  on(eventActions.updateProjectEventsInList, (state, { projectEvents }) => {
    const listProjectEvents = cloneDeep(state.listProjectEvents);
    projectEvents.forEach((projectEvent: ProjectEventModel) => {
      const indexToFind = listProjectEvents.findIndex(d => d.id === projectEvent.id);
      if (indexToFind > -1) {
        listProjectEvents[indexToFind] = projectEvent;
      }
    });

    return {
      ...state,
      listProjectEvents,
    };
  }),
);

export function eventReducer(state: EventState, action: Action): EventState {
  return reducer(state, action);
}
