import * as roleActions from './roles-and-permissions.actions';
import { appStateRole, ROLE_INITIAL_STATE, RoleState } from './roles-and-permissions.state';
import { cloneDeep, orderBy } from 'lodash-es';
import { Action, createReducer, on } from '@ngrx/store';

const reducer = createReducer(
  appStateRole.roles,
  on(roleActions.populateRolesList, (state, { roles }) => ({ ...state, list: roles })),
  on(roleActions.removeRoleFromList, (state, { id }) => {
    const index = state.list.findIndex(role => role.id === id);
    return index > -1 ? { ...state, list: state.list.slice(0, index).concat(state.list.slice(index + 1)) } : state;
  }),
  on(roleActions.resetCurrentRole, state => ({
    ...state,
    current: {
      ...state.current,
      role: { ...ROLE_INITIAL_STATE },
      roleSnapshot: { ...ROLE_INITIAL_STATE },
      pendingChanges: false,
      isLocked: false,
    },
  })),
  on(roleActions.populateCurrentRole, (state, { role }) => ({
    ...state,
    current: {
      ...state.current,
      role,
    },
  })),
  on(roleActions.populateApplications, (state, { applications }) => ({
    ...state,
    applications: orderBy(applications, ['order', 'asc']),
  })),
  on(roleActions.populateCurrentRoleSnapshot, (state, { role }) => ({
    ...state,
    current: { ...state.current, roleSnapshot: cloneDeep(role) },
  })),
  on(roleActions.updateRoleFromList, (state, { role }) => {
    const updateIndex = state.list.findIndex(d => d.id === role.id);
    return updateIndex > -1
      ? {
          ...state,
          list: [...[...state.list.slice(0, updateIndex)], { ...role }, ...[...state.list.slice(updateIndex + 1)]],
        }
      : state;
  }),
  on(roleActions.pendingCreateUpdateRole, (state, { pending }) => ({
    ...state,
    current: { ...state.current, pending },
  })),
  on(roleActions.dispatchedCreateUpdateRole, (state, { dispatched }) => ({
    ...state,
    current: { ...state.current, dispatched },
  })),
  on(roleActions.successCreateUpdateRole, (state, { success }) => ({
    ...state,
    current: { ...state.current, success },
  })),
  on(roleActions.addRoleToList, (state, { role }) => {
    return {
      ...state,
      list: [...state.list, { ...role }],
    };
  }),
  on(roleActions.updateRoleField, (state, { field, value }) => ({
    ...state,
    current: { ...state.current, role: { ...state.current.role, [field]: value } },
  })),
  on(roleActions.setActiveApplication, (state, { application }) => ({
    ...state,
    activeApplication: application,
  })),
  on(roleActions.setActiveApplicationById, (state, { id }) => {
    const application = state.applications.find(app => app.id === id);
    return {
      ...state,
      activeApplication: application,
    };
  }),
  on(roleActions.resetActiveApplication, state => ({
    ...state,
    activeApplication: null,
  })),
  on(roleActions.checkCurrentRoleForPendingChanges, (state, { hasChanges }) => ({
    ...state,
    current: {
      ...state.current,
      pendingChanges: hasChanges,
    },
  })),
  on(roleActions.populatePermissionsList, (state, { permissions }) => ({
    ...state,
    permissions: {
      ...state.permissions,
      list: permissions,
    },
  })),
  on(roleActions.populateRolePermissionsList, (state, { roles }) => ({
    ...state,
    permissions: {
      ...state.permissions,
      rolePermissions: roles,
    },
  })),
  on(roleActions.resetPermissionsState, state => ({
    ...state,
    permissions: {
      list: [],
      rolePermissions: [],
    },
  })),
);

export function roleReducer(state: RoleState, action: Action): RoleState {
  return reducer(state, action);
}
