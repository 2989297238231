import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppState } from './store/models/app.state';
import { errorPopup, messagePopup } from './store/actions/ui.actions';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService, User } from '@auth0/auth0-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from './shared/base.class';
import { getUserBySub, getUsersByOrganization } from './user/store/user.actions';
import { loadSettings } from './store/actions/settings.actions';

@Component({
  selector: 'phar-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<AppState>,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private appAuth: AuthService,
    public snackBar: MatSnackBar,
  ) {
    super();
  }

  ngOnInit(): void {
    this.appAuth.user$
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (user: User) => {
          this.store.dispatch(getUserBySub({ user }));
          this.store.dispatch(getUsersByOrganization());
        },
      });

    this.store
      .select(state => state.ui.error)
      .pipe(takeUntil(this.destroy$))
      .subscribe(error => {
        if (error) {
          this.snackBar.open(error, '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            panelClass: 'snackbar--alert-background',
          });
          this.store.dispatch(errorPopup({ error: '' }));
        }
      });

    this.store
      .select(state => state.ui.message)
      .pipe(takeUntil(this.destroy$))
      .subscribe(message => {
        if (message) {
          this.snackBar.open(message, '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            panelClass: 'snackbar--message',
          });
          this.store.dispatch(messagePopup({ message: '' }));
        }
      });

    /*this.store.select(state => state.permission.userPermissions).pipe(
      takeUntil(this.destroy$)
    ).subscribe(permissions => {
      const permissionsValues = permissions.map(p => p.value);
      this.permissionsService.flushPermissions();
      this.permissionsService.loadPermissions(permissionsValues);
    })*/

    this.registerCustomMaterialIcons();
    this.store.dispatch(loadSettings());
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  //
  registerCustomMaterialIcons() {
    const icons: string[] = [
      'alert',
      'arrow-down',
      'arrow-left',
      'arrow-right',
      'arrow-up',
      'bell',
      'bottomline',
      'calendar',
      'call',
      'card',
      'change',
      'check',
      'close',
      'consent',
      'critical',
      'download',
      'driver-card',
      'edit',
      'expired',
      'eye-off',
      'eye',
      'feather',
      'filter',
      'forms',
      'gender',
      'grid',
      'handle',
      'help',
      'home',
      'info',
      'insurance-card',
      'key',
      'language',
      'link-external',
      'list',
      'locate',
      'lock',
      'mail',
      'man',
      'middleline',
      'monitor',
      'more',
      'people',
      'phone',
      'placeholder',
      'plus',
      'printer',
      'question',
      'questionaires',
      'reconsents',
      'refresh',
      'required',
      'search',
      'settings',
      'sort-arrow-down',
      'sort-arrow-up',
      'sort-arrow',
      'swap',
      'topline',
      'trash',
      'upload',
      'user',
      'woman',
      'save',
      'send',
      'visibility',
      'library',
      'handyman',
      'folder',
      'duplicate',
      'play',
      'flag',
      'expand-5',
      'flag-1',
      'clock-rewind',
      'phone-2',
      'users',
      'link-new',
      'comment',
    ];

    icons.forEach(iconName => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${iconName}.svg`),
      );
    });
  }
}
